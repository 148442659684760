@tailwind base;

h1 {
  @apply text-3xl font-bold;
}
h2 {
  @apply text-2xl font-bold;
}
h3 {
  @apply text-xl font-bold;
}
h4 {
  @apply text-lg font-bold;
}

@tailwind components;
@tailwind utilities;
